import { Component, Renderer2, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-error-modal',
  template: `
	<div class="modal-header">
	  <button type="button" class="btn-close" (click)="bsModalRef.hide()"></button>
	</div>
	<div class="modal-body p-md-5">
	  <div class="text-center">
		<div class="text-danger">
		  <i class="bi bi-emoji-frown display-5"></i>
		</div>
		<div class="mt-4">
		  <h4 class="mb-2">Something went wrong.</h4>
		  <p class="text-muted fs-md mx-3 mb-0">Try whatever you were doing again.</p>
		  <!-- <pre>{{ error.error.message }}</pre> -->
		</div>
	  </div>
	</div>
  `,
})
export class ErrorModalComponent {
  error: any;
  constructor(public bsModalRef: BsModalRef) {}
}
